<template>
  <div class="container">
    <section class="header">
      <h1>{{ data.title }}</h1>
      <p>{{ data.createTime }}</p>
    </section>
    <section class="body" v-html="data.content"></section>
    <div class="attach">
        <a :href="item.url" v-for="item in attach" :download="item.name">附件：{{item.name}}</a>
    </div>
  </div>
</template>

<script>
import { Detail } from "@/api/knowledge";
export default {
  data() {
    return {
      data: {},
      attach:[]
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let id = this.$route.params.id;
      let res = await Detail(id);
      this.data = res.data.data;
      document.title = this.data.title;
      let arr = JSON.parse(res.data.data.attach)
      this.attach = arr.map(item=>{
          return {
              name:item.response.data.originName,
              url:process.env.VUE_APP_BASE_URL + '/' + item.response.data.url
          }
      })
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0.2rem;
}
h1 {
  color: #020202;
  font-size: 0.2rem;
  font-weight: normal;
}
.header {
    padding-bottom: 0.1rem;
    border-bottom: 1px solid #ccc;
}
.header p{
    color: #999;
    font-size: 0.14rem;
}
.body{
    padding-top: 0.1rem;
}
.attach{
    font-size: 0.14rem;
    margin-top: 0.2rem;
}
.attach a{
    display: block;
}
</style>